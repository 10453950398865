import JSBI from 'jsbi'

export const FACTORY_ADDRESS = '0xBeedC7c8489BdAE595Bf4E7555E90bC543447E11'

export const INIT_CODE_HASH = '0xa86d3fdead3afca30f50e9a67d7e0edbb6c21916379b1cc6b88196c1193b9b93'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
